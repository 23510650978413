import styled from 'styled-components';
import image from '../../../../../common/src/assets/image/hosting/Background/CTA.svg';
const HomectaWrapper = styled.section`
  padding: 0px 0px;
  // background: linear-gradient(
  //   270deg,
  //   rgba(25, 28, 60, 1) 0%,
  //   rgba(168, 41, 159, 1) 33%,
  //   rgba(242, 89, 130, 1) 100%
  // );
  background: rgb(135, 35, 128);
  background: linear-gradient(
    90deg,
    rgba(135, 35, 128, 1) 0%,
    rgba(25, 28, 60, 1) 100%
  );

  background: rgb(0, 14, 59);
  background: linear-gradient(
    90deg,
    rgba(0, 14, 59, 1) 36%,
    rgba(0, 35, 99, 1) 100%
  );

  &:before {
    content: '';
    background-image: url(${image});
    position: absolute;
    top: 0;
    /* left: -8.5%; */
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    /* background-position: center left; */
    background-position: center;
    /* background-repeat: no-repeat; */
    background-repeat: repeat-x;
    /* filter: opacity(0.2); */
    filter: opacity(0.15);
  }
  .advertisement_image {
    width: 100%;
  }
  .cta_inner_main {
    border-radius: 10px;
    overflow: hidden;
    // background: rgb(25, 28, 60);
    // background: linear-gradient(
    //   270deg,
    //   rgba(25, 28, 60, 1) 0%,
    //   rgba(168, 41, 159, 1) 33%,
    //   rgba(242, 89, 130, 1) 100%
    // );
  }
`;
export default HomectaWrapper;
