import React from 'react';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import Achievementimage from 'common/src/assets/image/hosting/news/DigitalTrans-Badge-02.webp';
import Achievementimage2 from 'common/src/assets/image/hosting/news/cio-achivement.webp';
import HomectaWrapper from './HomectaWrapper';

const Ctasection = ({
  name,
  column,
  buttonbox,
  imagebox,
  textbox,
  cta_size_container,
  mainrow,
  adtitle,
  adtext,
  title1,
  flexColumn,
}) => {
  return (
    <>
      <HomectaWrapper>
        <Container>
          <Box>
            <Heading {...title1} content="Featured In" />
          </Box>
          <Box {...mainrow} className="cta_inner_main">
            <Box {...column}>
              <Box {...flexColumn}>
                <Box {...imagebox}>
                  {/* <Link to="/miscellaneous/checklist-for-AI-automation"> */}
                  <img
                    src={Achievementimage}
                    className="advertisement_image"
                    alt="RAPs'achievement certificate in Analytics insight magazine"
                  ></img>
                  {/* </Link> */}
                </Box>
                <Box {...textbox}>
                  {/* <Heading
                {...adtitle}
                content="Recognized among the 10 Most Valuable Digital Transformation Companies for 2020 by The Analytics Insight"
              /> */}
                </Box>
                <Box {...buttonbox}>
                  {/* <Link to="/miscellaneous/checklist-for-AI-automation">
                <Text {...adtext} content={"Click here to checkout"} />
              </Link> */}
                  <Link
                    to="/news/analytics-insights-magazine"
                    className="btn btn_tertiary"
                  >
                    <span className="btn-text">View Article</span>
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box {...column}>
              <Box {...flexColumn}>
                <Box {...imagebox}>
                  {/* <Link to="/miscellaneous/checklist-for-AI-automation"> */}
                  <img
                    src={Achievementimage2}
                    className="advertisement_image"
                    alt="RAPs'achievement certificate in CIO review magazine"
                  ></img>
                  {/* </Link> */}
                </Box>
                <Box {...textbox}>
                  {/* <Heading
                {...adtitle}
                content="Recognized among the 10 Most Valuable Digital Transformation Companies for 2020 by The Analytics Insight"
              /> */}
                </Box>
                <Box {...buttonbox}>
                  {/* <Link to="/miscellaneous/checklist-for-AI-automation">
                <Text {...adtext} content={"Click here to checkout"} />
              </Link> */}
                  <Link to="/news/cio-review" className="btn btn_tertiary">
                    <span className="btn-text">View Article</span>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </HomectaWrapper>
    </>
  );
};

Ctasection.propTypes = {
  buttonbox: PropTypes.object,
  imagebox: PropTypes.object,
  textbox: PropTypes.object,
  mainrow: PropTypes.object,
};
Ctasection.defaultProps = {
  button: {
    type: 'button',
    fontSize: ['18px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  mainrow: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    p: '15px',
  },
  imagebox: {
    width: [1, 1, 0.4, 0.4, 0.4],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: ['20px', '20px', '20px', '20px', '20px'],
  },
  textbox: {
    width: [1, 1, 0.2, 0.2, 0.2],
    pl: ['0px', '0px', '10px', '10px', '10px'],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonbox: {
    width: [1, 1, 0.2, 0.2, 0.2],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: ['20px', '20px', '20px', '20px', '20px'],
  },
  advcolimg: {
    width: ['100%', '100%', '40%', '40%', '40%'],
    height: '100%',
  },
  advcoltext: {
    width: ['100%', '100%', '60%', '60%', '60%'],
    p: '10px',
  },
  adtitle: {
    fontSize: ['18px', '18px', '20px', '20px', '22px'],
    fontWeight: 'bold',
    // color: '#0f2137',
    color: '#ffffff',
    letterSpacing: '-0.025em',
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  adtext: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: 'normal',
    // color: '#0f2137',
    color: '#393E99',
    letterSpacing: '-0.025em',
    lineHeight: '1.31',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  column: {
    width: [1, 1, 0.5, 0.5, 0.5],
  },
  flexColumn: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#fff',
    // color: "#fff",
    letterSpacing: '-0.025em',
    pt: '20px',
    mb: '20px',
    textAlign: 'center',
  },
};

export default Ctasection;
